// const dev = {
//   API_ENDPOINT_URL: 'http://localhost:3002/api/v1'
// };

import { USER } from "constants/AuthConstant";

// const prod = {
//   API_ENDPOINT_URL: 'https://tcblapi.poindexters.ca/api/v1'
// };

// const test = {
//   API_ENDPOINT_URL: 'https://tcblapi.poindexters.ca/api/v1'
// };

const getEnv = () => {
	
	// switch (process.env.NODE_ENV) {
	// 	case 'development':
	// 		return dev
	// 	case 'production':
	// 		return prod
	// 	case 'test':
	// 		return test
	// 	default:
	// 		break;
	// }
	let roles = typeof localStorage.getItem(USER) === "string"
	? JSON.parse(localStorage.getItem(USER))
	: {};
	console.log(roles,'rolesroles')
	let url = ""
	if(roles?.appid?.toLowerCase()==='loc' || window.location.origin?.includes("leagueofchamps")) {
		url = process.env.REACT_APP_DEV_API_URL_LOC
	} else if(roles?.appid?.toLowerCase()==='tcbl' || window.location.origin?.includes("tcbl")) {
		url = process.env.REACT_APP_DEV_API_URL_TCBL
	} else {
		url = process.env.REACT_APP_DEV_API_URL
	}
	let redirecturl = ""
	if(roles?.appid?.toLowerCase()==='loc' || window.location.origin?.includes("leagueofchamps")) {
		redirecturl = process.env.REACT_APP_DEV_API_REDIRECT_URL_LOC
	} else if(roles?.appid?.toLowerCase()==='tcbl' || window.location.origin?.includes("tcbl")) {
		redirecturl = process.env.REACT_APP_DEV_API_REDIRECT_URL_TCBL
	} else {
		redirecturl = process.env.REACT_APP_DEV_API_URL
	}
	let appid = "leaguepro"
	if(roles?.appid?.toLowerCase()==='loc') {
		appid = "loc"
	} else if(roles?.appid?.toLowerCase()==='tcbl') {
		appid = 'tcbl'
	}
	return {
		API_ENDPOINT_URL: url,
		REDIRECT_URL: redirecturl,
		APPID: appid,
		roles: roles
	  };
}

export const env = getEnv()
