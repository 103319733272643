const mountBrandHeaders = () => {
  const domain = window.location.hostname;

  //default favicon and title
  let faviconSrc = "logo-sm.svg";
  let pageTitle = "League Pro";

  switch (true) {
    case domain.includes("tcbl"):
      faviconSrc = "tcbl.svg";
      pageTitle = "TCBL";
      break;

    default:
      faviconSrc = "logo-sm.svg";
      pageTitle = "League Pro";
      break;
  }
  const favicon = document.getElementById("favicon");
  if (favicon) {
    favicon.href = favicon.href.replace("logo-sm.svg", faviconSrc);
  } else {
    const link = document.createElement("link");
    link.id = "favicon";
    link.rel = "icon";
    link.href = faviconSrc;
    document.head.appendChild(link);
  }

  // Set page title
  document.title = pageTitle;
};

export default mountBrandHeaders;
