const FirebaseConfig = {
  apiKey: "AIzaSyB5ehVKNeR54lgYwLdUqdDYCKCVJGz_5eM",
  authDomain: "league-pro-885af.firebaseapp.com",
  databaseURL: "https://league-pro-885af-default-rtdb.firebaseio.com",
  projectId: "league-pro-885af",
  storageBucket: "league-pro-885af.appspot.com",
  messagingSenderId: "201904554714",
  appId: "1:201904554714:web:38b66a4e06b289bbf8dbe3",
  measurementId: "G-0C44YRRVSY"
};

export default FirebaseConfig

