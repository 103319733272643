import React  from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { AUTHENTICATED_ENTRY } from 'configs/AppConfig'
//import { env } from 'configs/EnvironmentConfig';

const PublicRoute = () => {
// 	var v1=""
// if (env.roles.role === "superadmin") {
// 	v1 = "../app/dashboards/default";
//   } else if (env.roles.role === "admin") {
// 	v1 = "../app/admindashboard";
//   } else if (env.roles.role === "player") {
// 	v1 = "../app/manager-manage-teams";
//   } else {
// 	v1 = "../app/manager-manage-teams";
//   }

	const { token } = useSelector(state => state.auth)
  
	return token ? <Navigate to={AUTHENTICATED_ENTRY} /> : <Outlet/>
}

export default PublicRoute